$background-color: #1e1e1f;
$prompt-color: #ff4aff;
$text-color: #ffffff;
$link-color: #40c5d1;

body {
  margin: 0;
}

section#primary-content {
  margin: 8px;
}

.command-line {
  margin: 4rem auto;
  min-width: 300px;
  max-width: 900px;
  min-height: 60%;
  max-height: 90%;
  background-color: $background-color;
  color: $text-color;
  font-family: 'SF Mono', SFMono-Regular, ui-monospace, 'DejaVu Sans Mono',
    Menlo, Consolas, monospace;
  font-size: 1rem;
  padding: 1rem;
  overflow: auto;
  border-radius: 4px;
  box-shadow: 0px 3px 15px adjust-color($background-color, $alpha: 0.2);

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .prompt {
    color: $prompt-color;
    margin-right: 1rem;
  }
  .output {
    color: darken($text-color, 40%);

    h4 {
      margin: 0 0 0.5rem 0;
    }

    p {
      margin: 0 0;
    }

    a {
      color: $link-color;
    }
  }
  .user-input {
    display: flex;

    input {
      flex: 1 !important;
      caret-color: $text-color;
      color: $text-color;
      font-family: inherit;
      font-size: inherit;
      background: transparent;
      border: none;
      outline: none;
      padding: 0;
    }
  }
}
